import "./index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { MsalCustomContextProvider } from "consent-ui-components";
import { AppInsightsContextProvider } from "consent-ui-components";
import { ApplicationOptionsContextProvider } from "consent-ui-components";

const root = createRoot(
  document.getElementById("widget-container") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApplicationOptionsContextProvider>
      <AppInsightsContextProvider>
        <MsalCustomContextProvider>
          <App id="widget-container" />
        </MsalCustomContextProvider>
      </AppInsightsContextProvider>
    </ApplicationOptionsContextProvider>
  </React.StrictMode>
);
