import { Consent, ConsentList } from "consent-ui-components";
import { AuthenticationTemplate } from "consent-ui-components";
import { ErrorHandlingContextProvider } from "consent-ui-components";
import './App.scss';

export interface AppProps {
    id: string;
}

const App : React.FC<AppProps> = ({id}) => {
    const container : HTMLElement | null = document.getElementById(id);

    let widgetType = "single-consent";
    if(container) {
        widgetType = (container as HTMLDivElement).hasAttribute("widget-type") ? (container as HTMLDivElement).getAttribute("widget-type") as string : "single-consent";
    }

    const renderWidget = (widgetType:string, container:HTMLElement) => {
        switch(widgetType) {
            case "single-consent":
                const memberId = container ? ((container as HTMLDivElement).getAttribute("member-id") as string): "";
                return <Consent memberId={memberId}/>
            case "multiple-consents":
                const memberIds = ((container as HTMLDivElement).getAttribute("member-id") as string);
                const memberIdsList = memberIds ? memberIds.split(",") : [];
                return <ConsentList memberIds={memberIdsList}/>;
            default:
                return <>Unknown widget type {widgetType}</>
        }
    }

    return (
        <div className="App">
            {container ? (
                <AuthenticationTemplate>
                    <ErrorHandlingContextProvider>
                        {renderWidget(widgetType, container)}
                    </ErrorHandlingContextProvider>
                </AuthenticationTemplate>
            ) :
            <div>
                No container div with id {id} could be found in document
            </div>
            }   
        </div>
    );
}

export default App;